
  import { mapState, mapActions } from 'vuex'

  export default {
    props: {
      options: {
        type: Array,
        default: () => []
      },
      requiredOnly: {
        type: Boolean,
        default: false
      },
      isModal: {
        type: Boolean,
        default: false
      },
      singleRequiredMod: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapState({
        choices: state => state.product.choices,
        product: state => state.product?.data
      }),
      showImages() {
        if (this.options && this.options.length > 0) {
          return !!this.options.find(option => {
            if ('modifier_image' in option.meta && option.meta['modifier_image']) {
              return true
            }
            return option.mods && option.mods.length > 0 
              ? option.mods.find(mod => 'modifier_image' in mod.meta && mod.meta['modifier_image'])
              : false
          })
        }

        return false
      },
    },
    methods: {
      ...mapActions({
        selectMod: 'product/selectMod',
        removeMod: 'product/removeMod',
      }),
      isSelected(id) {
        const choice = this.choices.find(choice => choice.id === id)
        if (choice) {
          return true
        } else {
          return false
        }
      },
      isSideModifier(name) {
        // Search through all modifiers to determine if the current modifier is a child of Right, Whole or Left
        let whole = false
        let left = false
        let right = false
        this.product.mods.forEach(mod => {
          mod.options.forEach(option => {
            option.mods.forEach(subMod => {
              if (subMod.name == 'Left') {
                left = subMod
              }
              if (subMod.name == 'Whole') {
                whole = subMod
              }
              if (subMod.name == 'Right') {
                right = subMod
              }
            })
          })
        })
        if (left) {
          if (JSON.stringify(left).includes(name)) {
            return true
          }
        }
        if (right) {
          if (JSON.stringify(right).includes(name)) {
            return true
          }
        }
        if (whole) {
          if (JSON.stringify(whole).includes(name)) {
            return true
          }
        }
        // Else
        return false
      },
    }
  }
