import { render, staticRenderFns } from "./ModifierOptions.vue?vue&type=template&id=a1ae6f72&scoped=true"
import script from "./ModifierOptions.vue?vue&type=script&lang=js"
export * from "./ModifierOptions.vue?vue&type=script&lang=js"
import style0 from "./ModifierOptions.vue?vue&type=style&index=0&id=a1ae6f72&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1ae6f72",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModifierOption: require('/opt/build/repo/components/product/ModifierOption.vue').default,ProductModifier: require('/opt/build/repo/components/product/ProductModifier.vue').default,SideOptions: require('/opt/build/repo/components/product/SideOptions.vue').default,HalfAndHalfOptions: require('/opt/build/repo/components/product/HalfAndHalfOptions.vue').default})
