
  import { mapState, mapActions } from 'vuex'

  export default {
    props: {
      option: {
        type: Object,
        default: () => {}
      },
      requiredOnly: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        supportsAlternateQuantity: false,
        alternateQuantityMultiplier: 1,
        showAll: false,
      }
    },
    computed: {
      ...mapState({
        choices: state => state.product.choices,
        product: state => state.product?.data
      }),
      mobile() {
        return this.$mq == 'sm' || this.$mq == 'md'
      },
      // additionalIngredients() {
      //   // Returns true if additional ingredients is selected
      //   return this.choices.find(choice => choice.name === 'Additional Ingredients')
      // },
      popular() {
        let popular = []
        const size = this.choices[0]?.name
        // if (this.choices && this.choices[0]) {
        //   if (this.choices[0].name.includes('Small')) size = 'Small'
        //   if (this.choices[0].name.includes('Medium')) size = 'Medium'
        //   if (this.choices[0].name.includes('Large')) size = 'Large'
        // }
        this.option.mods.forEach(mod => {
          // TODO: Convert this condition to a .find() method
          if (this.option.name.toLowerCase().includes(size.toLowerCase())) {
            mod.options.forEach(option => {
              option.mods.forEach(submod => {
                if (submod.name == 'Add Ingredients') {
                  submod.options.forEach(suboption => {
                    if (suboption.name !== 'Additional Ingredients') {
                      if (option.name !== 'Customize Whole') { // only include those under whole
                        return
                      }
                      popular.push(suboption)
                    } else {
                      // Set this to autoselect additional ingredients to enable those options when all is clicked
                      if (!this.isSelected(suboption.choice_id)) {
                        // console.log(suboption)
                        this.selectMod({ ...suboption, id: suboption.choice_id, quantity: 1 })
                      }
                    }
                  })
                }
              })
            })
          }
        })
        return popular
      },
      all() {
        let all = []
        const size = this.choices[0]?.name
        // let size = null
        // if (this.choices && this.choices[0]) {
        //   if (this.choices[0].name.includes('Small')) size = 'Small'
        //   if (this.choices[0].name.includes('Medium')) size = 'Medium'
        //   if (this.choices[0].name.includes('Large')) size = 'Large'
        // }
        this.option.mods.forEach(mod => {
          // TODO: Convert this condition to a .find() method
          if (this.option.name.toLowerCase().includes(size.toLowerCase())) {
            mod.options.forEach(option => {
              option.mods.forEach(submod => {
                if (submod.name == 'Add Ingredients' || submod.name == 'Popular Add-ons') {
                  submod.options.forEach(suboption => {
                    if (suboption.name == 'Additional Ingredients') {
                      if (!this.isSelected(suboption.choice_id)) {
                        this.selectMod({ ...suboption, id: suboption.choice_id, quantity: 1 })
                      }
                      suboption.mods.forEach(subsubmod => { // Proteins, Veggies, Cheeses
                        if (submod.name == 'Add Ingredients') {
                          subsubmod.options.forEach(subsuboption => {
                            if (option.name !== 'Customize Whole') { // only include those under whole
                              return
                            }
                            all.push(subsuboption)
                          })
                        }
                      })
                    }
                  })
                }
              })
            })
          }
        })
        return all
      }
    },
    methods: {
      ...mapActions({
        selectMod: 'product/selectMod',
      }),
      isSelected(id) {
        return this.choices.find(choice => choice.id === id)
      }
    }
  }
