
  import { mapState } from 'vuex'

  export default {
    props: {
      mod: {
        type: Object,
        default: [() => {}]
      },
      level: {
        type: Number,
        default: 1
      },
      requiredOnly: {
        type: Boolean,
        default: false
      },
      isModal: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapState({
        product: state => state.product?.data
      }),
      showModifier() {
        if (this.mod.selects.mandatory) {
          return true
        }
        if (this.mod.selects.min) {
          return true
        }
        if (this.requiredOnly) {
          return false
        }
        return true
      },
      singleRequiredMod() {
        // Single required default option
        return this.mod.options?.length === 1 && this.mod.options[0].default
      }
    },
  }
