import { render, staticRenderFns } from "./SideOptions.vue?vue&type=template&id=31983ee2&scoped=true"
import script from "./SideOptions.vue?vue&type=script&lang=js"
export * from "./SideOptions.vue?vue&type=script&lang=js"
import style0 from "./SideOptions.vue?vue&type=style&index=0&id=31983ee2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31983ee2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductModifier: require('/opt/build/repo/components/product/ProductModifier.vue').default,SideModifierOptions: require('/opt/build/repo/components/product/SideModifierOptions.vue').default,IconArrowDown: require('/opt/build/repo/components/icons/IconArrowDown.vue').default})
